import logo from './logo.svg';
import './App.css';

const riteCleanIp = "rtl.migserver.ml";
const mciCleanIp = "mci.migserver.ml";
const mtnCleanIp = "mtn.migserver.ml";
const riteCleanIp2 = "rtl2.migserver.ml";
const mciCleanIp2 = "mci2.migserver.ml";
const mtnCleanIp2 = "mtn2.migserver.ml";


function getIrancellConfig(id) {
  let config = `vless://${id}@${mtnCleanIp}:443?path=%2FCvBSNfgwWoylKKaW8OSvRYb7t&security=tls&encryption=none&alpn=http/1.1&host=cdn.migserver.ml&type=ws&sni=cdn.migserver.ml#ایرانسل شماره 1`;
  config += `\n vless://${id}@${mtnCleanIp2}:443?path=%2FCvBSNfgwWoylKKaW8OSvRYb7t&security=tls&encryption=none&alpn=http/1.1&host=cdn.migserver.ml&type=ws&sni=cdn.migserver.ml#ایرانسل شماره 2`;
  return config;

}
function getHamrahAvalCongig(id) {
  let config = `vless://${id}@${mciCleanIp}:443?path=%2FCvBSNfgwWoylKKaW8OSvRYb7t&security=tls&encryption=none&alpn=http/1.1&host=cdn.migserver.ml&type=ws&sni=cdn.migserver.ml#همراه اول شماره 1`;
  config += `\n vless://${id}@${mciCleanIp2}:443?path=%2FCvBSNfgwWoylKKaW8OSvRYb7t&security=tls&encryption=none&alpn=http/1.1&host=cdn.migserver.ml&type=ws&sni=cdn.migserver.ml#همراه اول شماره 2`;
  return config;
}
function getRitelCongig(id) {
  let config = `vless://${id}@${riteCleanIp}:443?path=%2FCvBSNfgwWoylKKaW8OSvRYb7t&security=tls&encryption=none&alpn=http/1.1&host=cdn.migserver.ml&type=ws&sni=cdn.migserver.ml#رایتل شماره 1`
  return config;
}
function vpnBoxGenrator(title, content,customText='') {
  
  if(customText!=''){
    title=customText;
  }
  else{
    title=`کپی کانفیگ ${title}`

  }

  return (

   
    <div class="d-grid gap-2">
      <button className='btn btn-primary' onClick={() => CopyText(content)}>
       {title}
      </button>

    </div>

  );
}
function App() {


  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  let mntConfig = "" + getIrancellConfig(id);
  let rtlConfig = "" + getRitelCongig(id);
  let mciConfig = "" + getHamrahAvalCongig(id);
  return (
    <div className="App">

      <div className='vpn-card-container'>

        {vpnBoxGenrator("ایرانسل", mntConfig)}
        {vpnBoxGenrator("رایتل", rtlConfig)}
        {vpnBoxGenrator("همراه اول", mciConfig)}
        {vpnBoxGenrator("", `${mciConfig}\n${rtlConfig}\n${mntConfig}`,"کپی تمامی کانفیگ ها")}

      </div>



    


    </div>

  );
}

function CopyText(text) {

  navigator.clipboard.writeText(text)
  alert("کپی شد")

}



export default App;
